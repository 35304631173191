.messagesField {
  border: 1px solid #f7f7f7;
  width: 85%;
  position: absolute;
  background-color: #FFF;

  margin: 0 auto;
  bottom: 20px;
  align-self: center;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 5px 10px 2px;

  border-radius: 20px;
  -webkit-box-shadow: 10px 10px 24px 1px rgba(223, 223, 223, 1);
  -moz-box-shadow: 10px 10px 24px 1px rgba(223, 223, 223, 1);
  box-shadow: 10px 10px 24px 1px rgba(223, 223, 223, 1);

  .button {
    flex: 0;    
    margin: 0;
    padding: 0;
    height: 27px;
    // margin-top: 5px;
    // border: 1px solid #000;

    > svg {
      margin: 0;      
    }
  }
  .input {
    margin-right: 5px;
    flex: 1;
    align-items: center;
    display: flex;
    // border: 1px solid #000;
  }

  textarea {
    margin: 0;
    border: none;
    resize: none;
    width: 100%;
    &.mobile {
      font-size: 16px;
    }
  }
}
