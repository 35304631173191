html {
  width: 100%;
  height: 100%;

  background-color: #f7f7f7;
}

body {
  width: 100%;
  height: 100%;
  margin: 0px;
  overscroll-behavior: none;
}

#root {
  width: 100%;
  height: 100%;
}

*:focus {
  outline: none;
}

* {
  box-sizing: content-box;
}

/* Chat Scrollbar */

@mixin chat-scrollbar($color, $width) {
  &::-webkit-scrollbar {
    width: $width;
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border: none;
    border-radius: $border-radius;
    background-color: transparent;
  }

  &:hover::-webkit-scrollbar-thumb {
    border: none;
    background-color: $color;
  }
}
