.chatHeader {
    height: 40px;
    display: flex;
    justify-content: flex-start;

    .close {
        cursor: pointer;
    }

    .logo {
        display: flex;
        align-items: center;
    }

    .title {
        display: flex;
        align-items: center;
        color: #FFF;
        font-size: 1rem;
        text-align: center;
        margin: 0 16px;
    }

    .name {
        display: flex;
        align-items: flex-start;
        color: #FFF;
        font-size: 0.95rem;
        text-align: center;
        margin: 4px 8px 0;
        flex-direction: column;
        line-height: 1.3;
    }

    .position {
        color: #FFF;
        font-size: 0.7rem;
        text-align: center;
        line-height: 1;
    }

    @media (max-width: 768px) {
        
    }
}