.avatar {
    height: 30px;
    width: 30px;
    min-width: 30px;
    position: relative;
    z-index: 99;

    .imageWrapper {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-weight: 700;
        color: #FFF;

        overflow: hidden;
        background: #005792;

        &.stroke {
            border: 2px solid #FFF;
        }
    }

    img {
        width: 100%;
        height: 100%;
    }
}
