.externalContainer {
  width: 100%;
  height: 100vh;
}
.externalWrapper {
  position: relative;
  height: 100%;
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
  background-color: #f7f7f7;
}