.splashScreen {
    width: 100%;
    height: 100%;
    background-color: aliceblue;
    justify-content: center;
    align-items: center;
    display: flex;
}

.logo {
    margin: 0 auto;
}