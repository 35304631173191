.chatHolder {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.dropZone {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  color: #555;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
