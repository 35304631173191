@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&display=swap);
/* reset */
*,
*::before,
*::after {
  box-sizing: border-box;
}

ul[class],
ol[class] {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

ul[class],
ol[class] {
  list-style: none;
}

a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input,
button,
textarea,
select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  * {
    -webkit-animation-duration: 0.01ms !important;
            animation-duration: 0.01ms !important;
    -webkit-animation-iteration-count: 1 !important;
            animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html{width:100%;height:100%;background-color:#f7f7f7}body{width:100%;height:100%;margin:0px;-ms-scroll-chaining:none;overscroll-behavior:none}#root{width:100%;height:100%}*:focus{outline:none}*{box-sizing:content-box}
.messagesField{border:1px solid #f7f7f7;width:85%;position:absolute;background-color:#fff;margin:0 auto;bottom:20px;align-self:center;display:flex;flex-direction:row;align-items:flex-end;padding:5px 10px 2px;border-radius:20px;box-shadow:10px 10px 24px 1px #dfdfdf}.messagesField .button{flex:0 1;margin:0;padding:0;height:27px}.messagesField .button>svg{margin:0}.messagesField .input{margin-right:5px;flex:1 1;align-items:center;display:flex}.messagesField textarea{margin:0;border:none;resize:none;width:100%}.messagesField textarea.mobile{font-size:16px}
.message-wrap{padding:8px 15px 0px;margin:5px 0;display:flex;flex-direction:column;align-items:flex-start}.message-wrap .message{width:80%;border:1px solid #ededed;background-color:#fff;box-shadow:10px 10px 24px 1px #dfdfdf;min-width:200px;padding:10px;padding-bottom:12px;border-radius:10px;word-wrap:break-word;position:relative}.message-wrap .message.last{margin-bottom:8px}.message-wrap .message.in.last:before{content:" ";position:absolute;width:0;height:0;left:-1px;right:auto;top:auto;bottom:-10px;border:10px solid;border-color:transparent transparent transparent #ededed}.message-wrap .message.in.last:after{content:" ";position:absolute;width:0;height:0;left:0px;right:auto;top:auto;bottom:-8px;border:10px solid;border-color:transparent transparent transparent #fff}.message-wrap .message.out{align-self:flex-end}.message-wrap .message.out.last:before{content:" ";position:absolute;width:0;height:0;left:auto;right:-1px;bottom:-10px;border:8px solid;border-color:#ededed #ededed transparent transparent}.message-wrap .message.out.last:after{content:" ";position:absolute;width:0;height:0;left:auto;right:0px;bottom:-10px;border:8px solid;border-color:#fff #fff transparent transparent}.message-wrap .message img{max-width:100%;max-height:300px}.message-wrap .message .btns{display:flex;justify-content:space-between;margin-top:8px;flex-wrap:wrap}.message-wrap .message .quickReply{font-weight:400;text-align:center;cursor:pointer;background-image:none;line-height:1.42857143;border-radius:4px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;border:0;color:#3a6d99;display:block;width:100%;background:#f0f4f7;font-size:14px;margin:0 5px;margin-bottom:10px;padding:6px}.message-wrap textarea{margin:0;border:none;resize:none;width:100%}.message-wrap textarea.mobile{font-size:16px}.message-wrap .seenCheck{position:absolute;bottom:-2px;right:2px;line-height:1}.message-from{font-weight:bold;color:#3a6d99}
.MessagesList_messagesList__16MuZ{flex:1 1;overflow-x:hidden;overflow-y:scroll;padding-bottom:65px;position:relative;padding-top:35px;flex-direction:column-reverse;display:flex;overscroll-behavior-y:contain}.MessagesList_messagesList__16MuZ::-webkit-scrollbar{width:6px;background:transparent}.MessagesList_messagesList__16MuZ::-webkit-scrollbar-track{background:transparent}.MessagesList_messagesList__16MuZ::-webkit-scrollbar-thumb{border:none;border-radius:10px;background-color:transparent}.MessagesList_messagesList__16MuZ:hover::-webkit-scrollbar-thumb{border:none;background-color:rgba(0,0,0,.3)}.MessagesList_dropZone__1HeIh{position:absolute;background-color:rgba(255,255,255,.5);top:0;bottom:0;width:100%;height:100%;z-index:999}.MessagesList_loading__2IIRR{display:flex;justify-content:center;align-items:center;padding-top:0px}.MessagesList_newMessagesPill__2RRR1{border-radius:50%;width:80%;max-width:180px;font-size:small;border:1px solid #ededed;background-color:#fff;box-shadow:10px 10px 24px 1px #dfdfdf;border-radius:20px;word-wrap:break-word;padding:4px 4px;overflow:hidden;position:fixed;margin:0 auto;left:0;right:0;bottom:70px;z-index:1;display:flex;align-items:center;justify-content:center;cursor:pointer}.MessagesList_newMessagesPill__2RRR1:hover{background-color:#ededed}.MessagesList_noMore__3Yw9_{width:50%;max-width:120px;border-bottom:1px solid #dfdfdf;margin:0 auto;margin-bottom:20px}
.SplashScreen_splashScreen__296sk{width:100%;height:100%;background-color:#f0f8ff;justify-content:center;align-items:center;display:flex}.SplashScreen_logo__3OwCB{margin:0 auto}
.OfflineModal_chatModal__3ukDg{position:absolute;left:50%;top:50%;transform:translate(-50%, -50%);padding:25px;background-color:#fff;border:1px solid #efefef;width:240px;border-radius:8px}.OfflineModal_chatModal__3ukDg input,.OfflineModal_chatModal__3ukDg textarea{width:100%;border:1px solid #efefef;padding:5px}.OfflineModal_chatModal__3ukDg button{background-color:#51718d;color:#fff;border:none;padding:5px 10px;border-radius:4px}.OfflineModal_chatModal__3ukDg .OfflineModal_buttons__2zTqD{display:flex;justify-content:space-around;margin-top:15px}.OfflineModal_chatModal__3ukDg .OfflineModal_attention__3gpf_{position:absolute;top:-20px;right:5px;border-radius:50%;overflow:hidden}
.chatHolder{display:flex;flex-direction:column;height:100%;width:100%}.dropZone{position:absolute;background-color:rgba(255,255,255,.5);top:0;bottom:0;width:100%;height:100%;z-index:999;color:#555;display:flex;justify-content:center;align-items:center;flex-direction:column}
.chatHeader{height:40px;display:flex;justify-content:flex-start}.chatHeader .close{cursor:pointer}.chatHeader .logo{display:flex;align-items:center}.chatHeader .title{display:flex;align-items:center;color:#fff;font-size:1rem;text-align:center;margin:0 16px}.chatHeader .name{display:flex;align-items:flex-start;color:#fff;font-size:.95rem;text-align:center;margin:4px 8px 0;flex-direction:column;line-height:1.3}.chatHeader .position{color:#fff;font-size:.7rem;text-align:center;line-height:1}
.avatar{height:30px;width:30px;min-width:30px;position:relative;z-index:99}.avatar .imageWrapper{width:100%;height:100%;border-radius:50%;display:flex;align-items:center;justify-content:center;font-size:15px;font-weight:700;color:#fff;overflow:hidden;background:#005792}.avatar .imageWrapper.stroke{border:2px solid #fff}.avatar img{width:100%;height:100%}
.PollModal_chatModal__279n4{position:absolute;left:50%;top:50%;transform:translate(-50%, -50%);padding:25px;background-color:#fff;border:1px solid #efefef;width:240px;border-radius:8px}.PollModal_chatModal__279n4 input,.PollModal_chatModal__279n4 textarea{width:100%;border:1px solid #efefef;padding:5px}.PollModal_chatModal__279n4 button{background-color:#51718d;color:#fff;border:none;padding:5px 10px;border-radius:4px}.PollModal_chatModal__279n4 .PollModal_buttons__1uzhx{display:flex;justify-content:space-around;margin-top:15px}.PollModal_chatModal__279n4 .PollModal_attention__3MuPo{position:absolute;top:-20px;right:5px;border-radius:50%;overflow:hidden}.PollModal_starsContainer__3CCyu{margin-bottom:10px;text-align:center}.PollModal_star__UBNHw:hover{cursor:pointer}
.externalContainer{width:100%;height:100vh}.externalWrapper{position:relative;height:100%;max-width:600px;margin-right:auto;margin-left:auto;background-color:#f7f7f7}
.PersonalInfoModal_required__j02lR{color:#c70039}.PersonalInfoModal_chatModal__22Phh{position:absolute;left:50%;top:50%;transform:translate(-50%, -50%);padding:25px;background-color:#fff;border:1px solid #efefef;width:240px;border-radius:8px}.PersonalInfoModal_chatModal__22Phh input,.PersonalInfoModal_chatModal__22Phh textarea{width:100%;border:1px solid #efefef;padding:5px}.PersonalInfoModal_chatModal__22Phh button{background-color:#51718d;color:#fff;border:none;padding:5px 10px;border-radius:4px}.PersonalInfoModal_chatModal__22Phh .PersonalInfoModal_buttons__3Ivvv{display:flex;justify-content:space-around;margin-top:15px}.PersonalInfoModal_chatModal__22Phh .PersonalInfoModal_attention__3zxt4{position:absolute;top:-20px;right:5px;border-radius:50%;overflow:hidden}
