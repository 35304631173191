/* Chat Scrollbar */

@mixin chat-scrollbar($color, $width) {
  &::-webkit-scrollbar {
    width: $width;
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border: none;
    border-radius: 10px;
    background-color: transparent;
  }

  &:hover::-webkit-scrollbar-thumb {
    border: none;
    background-color: $color;
  }
}


.messagesList {
  flex: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-bottom: 65px;
  position: relative;
  padding-top: 35px;

  flex-direction: column-reverse;
  display: flex;

  @include chat-scrollbar(rgba(0,0,0 , 0.3), 6px);

  overscroll-behavior-y: contain;
}

.dropZone {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
}

.newMessagesPill {
  border-radius: 50%;
  width: 80%;
  max-width: 180px;
  font-size: small;

  border: 1px solid #ededed;
  background-color: #fff;
  box-shadow: 10px 10px 24px 1px #dfdfdf;

  border-radius: 20px;
  word-wrap: break-word;

  padding: 4px 4px;
  
  overflow: hidden;
  position: fixed;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 70px;

  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: #ededed;
  }
}

.noMore {
  width: 50%;
  max-width: 120px;
  border-bottom: 1px solid #dfdfdf;
  margin: 0 auto;
  margin-bottom: 20px;
}