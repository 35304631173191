// .in + .out {
//   background-color: red;
// }

// .out + .in {
//   background-color: green;
// }

.message-wrap {
  padding: 8px 15px 0px;
  margin: 5px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .message {
    width: 80%;
    border: 1px solid #ededed;
    background-color: #fff;
    -webkit-box-shadow: 10px 10px 24px 1px rgba(223, 223, 223, 1);
    -moz-box-shadow: 10px 10px 24px 1px rgba(223, 223, 223, 1);
    box-shadow: 10px 10px 24px 1px rgba(223, 223, 223, 1);
    min-width: 200px;
    padding: 10px;
    padding-bottom: 12px;
    border-radius: 10px;
    word-wrap: break-word;

    position: relative;

    &.last {
      margin-bottom: 8px;
    }

    &.in.last {
      /*Right triangle, placed bottom left side slightly in*/
      &:before {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: -1px;
        right: auto;
        top: auto;
        bottom: -10px;
        border: 10px solid;
        border-color: transparent transparent transparent #ededed;
      }
      &:after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: 0px;
        right: auto;
        top: auto;
        bottom: -8px;
        border: 10px solid;
        border-color: transparent transparent transparent #fff;
      }
    }
    &.out {
      align-self: flex-end;
      &.last {
        &:before {
          content: ' ';
          position: absolute;
          width: 0;
          height: 0;
          left: auto;
          right: -1px;
          bottom: -10px;
          border: 8px solid;
          border-color: #ededed #ededed transparent transparent;
        }
        &:after {
          content: ' ';
          position: absolute;
          width: 0;
          height: 0;
          left: auto;
          right: 0px;
          bottom: -10px;
          border: 8px solid;
          border-color: #fff #fff transparent transparent;
        }
      }
    }
    img {
      max-width: 100%;
      max-height: 300px;
    }
    .btns {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
      flex-wrap: wrap;
    }
    .quickReply {      
      font-weight: 400;
      text-align: center;
      // flex-basis: 38%;
      cursor: pointer;
      background-image: none;
      // white-space: nowrap;
      line-height: 1.42857143;
      border-radius: 4px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border: 0;
      color: #3a6d99;
      display: block;
      width: 100%;
      background: #f0f4f7;
      // height: 20px;
      font-size: 14px;
      margin: 0 5px;
      margin-bottom: 10px;
      padding: 6px;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
  }

  textarea {
    margin: 0;
    border: none;
    resize: none;
    width: 100%;
    &.mobile {
      font-size: 16px;
    }
  }

  .seenCheck{
    position: absolute;
    bottom: -2px;
    right: 2px;
    line-height: 1;
  }
}

.message-from {
  font-weight: bold;
  color: #3a6d99;
}