
.chatModal {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 25px;
    background-color: #FFF;
    border: 1px solid #efefef;
    width: 240px;
    border-radius: 8px;

    input, textarea {
      width: 100%;
      border: 1px solid #efefef;
      padding: 5px;
    }

    button {
      background-color: #51718d;
      color: #FFF;
      border: none;
      padding: 5px 10px;
      border-radius: 4px;
    }

    .buttons {
      display: flex;
      justify-content: space-around;
      margin-top: 15px;
    }

    .attention {
      position: absolute;
      top: -20px;
      right: 5px;
      border-radius: 50%;
      overflow: hidden;  
    }
}